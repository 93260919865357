import { default as abuseReportsMpymQ0ZGn0Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93UgjTQup9JCMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexY7YIuxiODpMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/index.vue?macro=true";
import { default as moderation2UwHqnKBT3Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/moderation.vue?macro=true";
import { default as pendingBansdFEkmTdSwbMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/pendingBans.vue?macro=true";
import { default as profileskGhhAsH7X4Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/profiles.vue?macro=true";
import { default as expensesZViDfYt0DIMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexdOi9iIBIN8Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/timesheets/index.vue?macro=true";
import { default as overview5AlYxyFsiYMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingK50v7sp8vRMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingHfxaFkS5frMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/translations/missing.vue?macro=true";
import { default as users4RpUNCtadxMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/admin/users.vue?macro=true";
import { default as apiyFMRJm1fX4Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/api.vue?macro=true";
import { default as _91slug_93Kr9kjfyjIMMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/blog/[slug].vue?macro=true";
import { default as indexaq8vt7YCztMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93FxJYGBQSyPMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93rlTEn9HznvMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as admin8Qtz2b2kuqMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/census/admin.vue?macro=true";
import { default as index00vYoQEwnbMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/census/index.vue?macro=true";
import { default as contactuoI5cWfI2ZMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/contact.vue?macro=true";
import { default as design9pqclzHs09Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/design.vue?macro=true";
import { default as englishfH3hjc8LyEMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/english.vue?macro=true";
import { default as faqGZQbrscD4LMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/faq.vue?macro=true";
import { default as inclusive9PArH8826ZMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/inclusive.vue?macro=true";
import { default as indexbcy6U7AbtzMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/index.vue?macro=true";
import { default as license3kzRPDv6oYMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/license.vue?macro=true";
import { default as academicYT4aU6RXnZMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/links/academic.vue?macro=true";
import { default as indexFLZRFWWwoeMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/links/index.vue?macro=true";
import { default as mediadQOODSqLTGMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/links/media.vue?macro=true";
import { default as translinguisticsYzarCvS2CuMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/links/translinguistics.vue?macro=true";
import { default as zine1TzbdPHLuwMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/links/zine.vue?macro=true";
import { default as names6ToBUxyFR4Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/names.vue?macro=true";
import { default as indexx3GPjPfBwUMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/nouns/index.vue?macro=true";
import { default as templatesbWLKaUdRgyMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/nouns/templates.vue?macro=true";
import { default as peopleg56lGESsYzMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/people.vue?macro=true";
import { default as privacyj7L4ZmxzbzMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/privacy.vue?macro=true";
import { default as _91username_93AzsmwEK5CYMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93de6zEmWl4lMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/profile/card-[username].vue?macro=true";
import { default as editoruVZXKbG39OMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/profile/editor.vue?macro=true";
import { default as anygpIODBZhdmMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/any.vue?macro=true";
import { default as avoidingrsoh6xTV72Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/avoiding.vue?macro=true";
import { default as indexBTtuUjAvtXMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/index.vue?macro=true";
import { default as mirrorWvEPt9ALi6Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/mirror.vue?macro=true";
import { default as pronounsmB5Wgsiq7Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesChoF1bicO0Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/sources.vue?macro=true";
import { default as team4jjjuL55grMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/team.vue?macro=true";
import { default as terminologyfuqY1jvQ0oMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/terminology.vue?macro=true";
import { default as terms29FEGjtiTEMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/terms.vue?macro=true";
import { default as userNYjQJaWgxIMeta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/user.vue?macro=true";
import { default as workshopsMlJFtUJ9B3Meta } from "/home/admin/www/pronouns.page/release/20241010205102/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsMpymQ0ZGn0Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansdFEkmTdSwbMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiyFMRJm1fX4Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93Kr9kjfyjIMMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexaq8vt7YCztMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93FxJYGBQSyPMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93rlTEn9HznvMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: admin8Qtz2b2kuqMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: index00vYoQEwnbMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactuoI5cWfI2ZMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishfH3hjc8LyEMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqGZQbrscD4LMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusive9PArH8826ZMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicYT4aU6RXnZMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexFLZRFWWwoeMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediadQOODSqLTGMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsYzarCvS2CuMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zine1TzbdPHLuwMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: names6ToBUxyFR4Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexx3GPjPfBwUMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesbWLKaUdRgyMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleg56lGESsYzMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyj7L4ZmxzbzMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93AzsmwEK5CYMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93de6zEmWl4lMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editoruVZXKbG39OMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anygpIODBZhdmMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingrsoh6xTV72Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexBTtuUjAvtXMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorWvEPt9ALi6Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounsmB5Wgsiq7Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesChoF1bicO0Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team4jjjuL55grMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyfuqY1jvQ0oMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms29FEGjtiTEMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userNYjQJaWgxIMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsMlJFtUJ9B3Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20241010205102/pages/workshops.vue")
  }
]